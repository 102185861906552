<!--
 * @Description: 
 * @Author: 爱酱丶
 * @Date: 2022-11-03 17:07:20
 * @LastEditTime: 2022-11-15 11:26:27
 * @LastEditors: 爱酱丶
-->
<template>
  <div class="header">
    <van-row class="add-box" type="flex" justify="space-between">
      <van-col span="4" class="arrow-left">
        <div @click="back">
          <van-icon name="arrow-left" size="20" v-if="!noLeft" />
        </div>
      </van-col>
      <van-col span="16" style="font-weight: bold">
        <span>{{ totalName }}</span>
      </van-col>
      <van-col
        span="4"
        @click="goRouter"
        v-if="rightRouter"
        class="rightTitle"
        >{{ rightTitle }}</van-col
      >
      <van-col span="4" @click="handleClick" v-else class="rightTitle">{{
        rightTitle
      }}</van-col>
    </van-row>
  </div>
</template>
<script>
export default {
  props: {
    totalName: {
      type: [String],
      default: ''
    },
    noLeft: {
      type: [Boolean],
      default: false
    },
    query: {
      type: Object,
      default() {
        return {}
      }
    },
    routerName: {
      type: String,
      default: ''
    },
    rightTitle: {
      type: String,
      default: ''
    },
    rightRouter: {
      type: String,
      default: ''
    }
  },
  methods: {
    back() {
      if (this.routerName !== '') {
        this.$router.push({ name: this.routerName, query: this.query })
      } else {
        this.$router.go(-1)
      }
    },
    goRouter() {
      if (this.rightRouter) {
        this.$router.push({ name: this.rightRouter })
      }
    },
    handleClick() {
      this.$emit('clickRight')
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
.header
  position fixed
  left 0
  right 0
  top 0
  z-index 99
  font-size 16px
  background rgb(16, 32, 48)

  .van-row  {
    margin: 0 auto
    width 70%

  }
  .van-col
    height 60px
    flex-center()
  .rightTitle
    font-size 13px
    padding-right 10px
  .arrow-left
    justify-content flex-start
    padding-left 16px
</style>
