<template>
  <div class="container">
    <PublicHeader :totalName="$t('lockming').title" />
    <div class="content">
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="line top" style="margin-right:50px">
            <span class="name">{{ showTitle(item) }}</span>
            <span class="btn">{{
              item.IsEnd ? $t('lockming').finished : $t('lockming').doing
            }}</span>
          </div>
          <div class="line" style="margin-right:25px">
            <div>{{ $t('lockming').sl }}：{{ item.BuyNum }}</div>
            <div>{{ $t('lockming').shouyilv }}：{{ item.Rate }}%</div>
            <div>
              {{ $t('lockming').scqx }}：{{ item.DayNum
              }}{{ $t('lockming').day }}
            </div>
          </div>
          <div class="line date">
            <div>
              <div>{{ $t('lockming').ks }}：</div>
              <div>{{ item.StartTime | getLocalTime }}</div>
            </div>
            <div>
              <div>{{ $t('lockming').js }}：</div>
              <div>{{ item.EndTime | getLocalTime }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components_pc/publicHeaderNew'
import productApi from '@/api/product'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      list: [],
      form: {
        page: 1,
        size: 10
      },
      loading: false,
      finished: false
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  methods: {
    showTitle(item) {
      if (this.lang === 'zh') {
        return item.ChineseName
      } else {
        return item.EnglishName
      }
    },
    async getList() {
      const res = await productApi.getMiningRecords(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  .content {
    margin: 20px;
    border-radius: 4px;
    .list-box {
      height: calc(100vh - 64px);
      overflow: scroll;
    }
    .item {
      margin: 10px auto;
      width: 70%;
      background: #17181e;
      padding: 10px 30px 10px 30px;
      border-bottom: 1px solid #1f253c;
      .line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      .top {
        margin-top: 5px;
        font-weight: bold;
        display: flex;
        align-items: center;
        .btn {
          width: 60px;
          height: 25px;
          font-size: 12px;
          background: #00abff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
        }
      }
      .name {
        font-size: 16px;
      }
      .date {
        color: #999;
        line-height: 1.5;
      }
    }
  }
}
</style>
